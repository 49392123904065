import { mapState } from "vuex";
import { saveLog } from "@/modules/logger";
import Vimeo from "@/components/vimeo";
import { UI_RECORD_INSTRUCTIONS } from "@/constants";

export default {
  components: { Vimeo },

  data: () => ({
    view: "video",
    titles: UI_RECORD_INSTRUCTIONS,
  }),

  computed: {
    ...mapState(["recording"]),

    title() {
      return this.titles[this.view].title || "";
    },

    isVideo() {
      return this.view === "video";
    },

    isText() {
      return this.view === "text";
    },

    isExample() {
      return this.view === "example";
    },

    links() {
      return Object.entries(this.titles)
        .map(([key, { title, link }]) => ({ key, title, link }))
        .filter(({ key }) => key !== this.view);
    },
  },

  methods: {
    changeView(type) {
      this.view = type;
      saveLog("change-instructions", { type });
    },
  },
};
