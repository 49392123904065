<template>
  <div class="vimeo" :class="{ disabled }">
    <div id="video-iframe" :data-vimeo-url="url" class="vimeo__player"></div>
  </div>
</template>
<script>
import Player from "@vimeo/player";
import { saveLog } from "@/modules/logger";

export default {
  mounted() {
    this.player = new Player("video-iframe");

    this.player.on("play", () => {
      this.onPlay();
    });

    this.player.on("pause", () => {
      this.onPause();
    });
  },

  props: {
    disabled: { type: Boolean, default: false },
    url: { type: String, required: true },
    id: { type: String, required: true },
  },

  data: () => ({
    player: null,
    rendered: true,
  }),

  watch: {
    disabled(value) {
      if (!value) return;

      this.pause();
    },
  },

  methods: {
    pause() {
      this.player.pause();
    },

    onPlay() {
      saveLog("video-play");
    },

    onPause() {
      saveLog("video-pause");
    },
  },
};
</script>

<style lang="scss">
.vimeo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.disabled {
    opacity: 0.75;
  }

  &__player {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
